import React, { createContext, useState, useContext, ReactNode } from "react";

interface MapContextType {
  mapLoaded: boolean;
  dataLoaded: boolean;
  setMapLoaded: (loaded: boolean) => void;
  setDataLoaded: (loaded: boolean) => void;
}

const MapContext = createContext<MapContextType | undefined>(undefined);

export const MapProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [mapLoaded, setMapLoaded] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  return (
    <MapContext.Provider
      value={{ mapLoaded, dataLoaded, setMapLoaded, setDataLoaded }}
    >
      {children}
    </MapContext.Provider>
  );
};

export const useMapContext = () => {
  const context = useContext(MapContext);
  if (!context) {
    throw new Error("useMapContext must be used within a MapProvider");
  }
  return context;
};
