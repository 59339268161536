import React from "react";
import PopupIcon from "../icons/PopupIcon";
interface GradientBarProps {
  tlr: number;
  tlrPercentage: number;
  tlrColor: string;
}
const GradientBar = ({ tlr, tlrPercentage, tlrColor }: GradientBarProps) => (
  <div className="relative w-full h-6 flex items-center justify-center">
    {/* Barra difuminada de fondo */}
    <div
      className="absolute top-0 left-0 w-full h-full rounded-full"
      style={{
        filter: "blur(10px)",
        background:
          "linear-gradient(to right, #FF0000, #FF4500, #FF7F00, #FFBF00, #FFFF00, #BFFF00, #80FF00, #40FF00, #00FF00)",
      }}
    ></div>

    {/* Barra principal */}
    <div
      className="relative w-full px-5 rounded-full h-5 shadow-lg flex items-center justify-center"
      style={{
        background:
          "linear-gradient(to right, #FF0000, #FF4500, #FF7F00, #FFBF00, #FFFF00, #BFFF00, #80FF00, #40FF00, #00FF00)",
      }}
    >
      <div className="relative w-full h-0">
        <div
          className="absolute w-0 h-0 bg-white"
          style={{ left: `${tlrPercentage}%` }}
        >
          <div className="w-16 h-auto transform -translate-x-1/2 -translate-y-full">
            <PopupIcon />
            <div className="w-full h-full absolute font-ibm text-[8px] top-0 left-0  pt-1.5 font-medium pb-3 p-3.5 flex justify-center items-center space-x-1">
              <span>TRL</span>
              <span
                className="w-6 aspect-square flex items-center justify-center rounded-full "
                style={{ background: tlrColor }}
              >
                {tlr}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default GradientBar;
