import { useEffect } from "react";
// import logo from "./logo.svg";
import { ApplicationLoader, Map } from "./components";

import { MapProvider } from "./context/MapContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import StationView from "./views/StationView/StationView";
import ShowIntroductionVideo from "./components/ShowIntroductionVideo";
import Minisite from "./views/Minisite";

function App() {
  // Estado para el modo tótem
  //  const [isTotemMode, setIsTotemMode] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const mode = searchParams.get("mode");

    if (mode) {
      // Si el parámetro 'mode' está definido
      if (mode === "totem") {
        localStorage.setItem("isTotemMode", "true");
      } else {
        localStorage.setItem("isTotemMode", "false");
      }
    } else {
      // Si el parámetro 'mode' no está definido, recuperar el estado de localStorage
      const savedMode = localStorage.getItem("isTotemMode") === "true";
    }
  }, []);

  useEffect(() => {
    const handleContextMenu = (e: MouseEvent) => {
      e.preventDefault();
    };

    document.addEventListener("contextmenu", handleContextMenu);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  return (
    <Router>
      <MapProvider>
        <Map />
        <img
          src="/logo.png"
          alt="logo Impacta UANDES"
          className="pointer-events-none absolute top-4 left-4 md:top-10 md:left-10 w-56"
        />

        <ShowIntroductionVideo />

        <ApplicationLoader />
        <Routes>
          <Route path="/minisite/:stationId" element={<Minisite />} />
          <Route path="/station/:stationId" element={<StationView />} />
        </Routes>
      </MapProvider>
    </Router>
  );
}

export default App;
