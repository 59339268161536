import React from "react";

const HomeIcon = () => (
  <svg
    className="w-full h-auto"
    data-name="Home"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 128 128"
  >
    <circle fill="#fff" cx="64" cy="64" r="64" />
    <g>
      <rect strokeWidth={0} x="74.93" y="54.96" width="9.56" height="10.99" />
      <rect strokeWidth={0} x="43.46" y="54.96" width="9.56" height="10.99" />
      <path
        strokeWidth={0}
        d="M98.69,44.3l-32.33-26.93-6.2.07-30.92,26.93-1.64,3.6v53.46l4.78,4.78h63.25l4.78-4.78v-53.46l-1.72-3.67ZM90.85,96.66h-22.07v-27.16h-9.56v27.16h-22.07v-46.51l26.21-22.82,27.48,22.89v46.44Z"
      />
    </g>
  </svg>
);

export default HomeIcon;
