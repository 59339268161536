import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Unity, useUnityContext } from "react-unity-webgl";
import GradientBar from "./components/GradientBar";
import { InfoIcon, HomeIcon } from "./icons";
import gsap from "gsap";
import { QRCodeSVG } from "qrcode.react";
import Loader from "../../components/Loader";
const StationView: React.FC = () => {
  const { stationId } = useParams<{ stationId: string }>();
  const navigate = useNavigate();
  //animation
  const notificationRef = useRef(null);
  const moreInfoButtonRef = useRef(null);
  const homeButtonRef = useRef(null);
  const viewRef = useRef(null);
  const informativeRef = useRef<HTMLDivElement>(null);

  const [tlr, setTLR] = useState<number>(9);
  const [tlrExplanation, setTLRExplanation] = useState<string>("");
  const [stationIntelectualProperty, setStationIntelectualProperty] =
    useState<string>("");
  const [stationPartners, setStationPartners] = useState<string>("");

  const [tlrColor, setTLRColor] = useState<string>("");

  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const [pdfLink, setPdfLink] = useState<string>("");
  const pdfRef = useRef<HTMLIFrameElement>(null);
  const pdfContainerRef = useRef<HTMLDivElement>(null);

  // Estado para el modo tótem
  const [isTotemMode, setIsTotemMode] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const mode = searchParams.get("mode");

    if (mode) {
      // Si el parámetro 'mode' está definido
      if (mode === "totem") {
        setIsTotemMode(true);
        localStorage.setItem("isTotemMode", "true");
      } else {
        setIsTotemMode(false);
        localStorage.setItem("isTotemMode", "false");
      }
    } else {
      // Si el parámetro 'mode' no está definido, recuperar el estado de localStorage
      const savedMode = localStorage.getItem("isTotemMode") === "true";
      setIsTotemMode(savedMode);
    }
  }, []);

  const { unityProvider, isLoaded, loadingProgression, unload } =
    useUnityContext({
      loaderUrl:
        "https://d1g2o4pwbvyrni.cloudfront.net/impacta-uandes/v3/uandes/Build/uandes.loader.js?",
      dataUrl:
        "https://d1g2o4pwbvyrni.cloudfront.net/impacta-uandes/v3/uandes/Build/uandes.data.br",
      frameworkUrl:
        "https://d1g2o4pwbvyrni.cloudfront.net/impacta-uandes/v3/uandes/Build/uandes.framework.js.br",
      codeUrl:
        "https://d1g2o4pwbvyrni.cloudfront.net/impacta-uandes/v3/uandes/Build/uandes.wasm.br",
      cacheControl: (url: string) => {
        if (url.includes("https://riolab.admin.rdx.social/api/")) {
          return "must-revalidate";
        }
        console.log("cacheControl", url);
        return "immutable";
      },
      companyName: "Riolab",
      productName: "Uandes",
      productVersion: "0.1",
    });

  const onClosed = () => {
    gsap.to(viewRef.current, {
      autoAlpha: 0,
      duration: 0.6,
      ease: "power2.out",
      onComplete: () => {
        unload();
        navigate("/");
      },
    });
  };

  const showPDF = () => {
    gsap.killTweensOf(pdfContainerRef.current);
    gsap.killTweensOf(pdfRef.current);

    gsap.to(pdfContainerRef.current, {
      autoAlpha: 1,
      duration: 0.6,
      ease: "power2.out",
    });
    gsap.to(pdfRef.current, {
      scale: 1,
      duration: 0.6,
      ease: "power2.out",
    });
  };
  const hidePDF = () => {
    gsap.killTweensOf(pdfContainerRef.current);
    gsap.killTweensOf(pdfRef.current);

    gsap.to(pdfContainerRef.current, {
      autoAlpha: 0,
      duration: 0.6,
      ease: "power2.in",
    });
    //scale pdfRef
    gsap.to(pdfRef.current, { scale: 0.5, duration: 0.6, ease: "power2.in" });
  };
  const [devicePixelRatio, setDevicePixelRatio] = useState(
    window.devicePixelRatio
  );
  const loadingPercentage = Math.round(loadingProgression * 100);
  const tlrPercentage = Math.round((tlr / 9) * 100);

  useEffect(() => {
    const explanations: { [key: number]: string } = {
      1: "TRL 1 - Principios básicos observados y reportados.",
      2: "TRL 2 - Concepto y/o aplicación tecnológica formulada.",
      3: "TRL 3 - Función crítica analítica y experimental y/o prueba de concepto característica.",
      4: "TRL 4 - Validación de componentes y/o disposición de los mismos en entorno de laboratorio.",
      5: "TRL 5 - Validación de componentes y/o disposición de los mismos en un entorno relevante.",
      6: "TRL 6 - Modelo de sistema o subsistema o demostración de prototipo en un entorno relevante.",
      7: "TRL 7 - Demostración de sistema o prototipo en un entorno real.",
      8: "TRL 8 - Sistema completo y certificado a través de pruebas y demostraciones.",
      9: "TRL 9 - Sistema probado con éxito en entorno real.",
    };
    const intelectualProperty: { [key: number]: string } = {
      1: "Patente provisional",
      2: "Derecho de autor y Marca",
      3: "Secreto industrial",
      4: "Secreto industrial",
      5: "Secreto industrial",
      6: "Patente",
      7: "Patente CL 03648-2018,Patente EP17 734 143.5",
      8: "Derecho de autor y Marca",
      9: "Marca",
      10: "Patente",
    };

    const partners: { [key: number]: string } = {
      1: "Valtek Diagnostic y Clínica Dávila",
      2: "Colegium S.A., MetaMetrics Inc.",
      3: "Ministerio Público, Sosafe",
      4: "Webdox, Pertinencias, SEA",
      5: "Asociación de Empresas Eléctricas AG y Galfano y Compañía Ltda.",
      6: "Metropipping SPA y Optima Water Well Screens",
      7: "Agrosuper y C4C",
      8: "Colegium S.A. y MetaMetrics Inc.",
      9: "Municipalidad de Puente Alto y AMPSA",
      10: "INVEXOR",
    };

    const colors: { [key: number]: string } = {
      1: "#FF0000",
      2: "#FF4500",
      3: "#FF7F00",
      4: "#FFBF00",
      5: "#FFFF00",
      6: "#BFFF00",
      7: "#80FF00",
      8: "#40FF00",
      9: "#00FF00",
    };

    const stationTLRs: { [key: number]: number } = {
      321: 3, // Bipolaridad
      322: 2, // Leer+
      323: 4, // Redes Delictuales
      324: 3, // Certeza Jurídica
      325: 4, // Arbocensus
      326: 3, // Biopurificador
      327: 5, // Gel X
      328: 9, // Dialect
      329: 3, // Discapacidad
      330: 5, // Pregnóstica
    };
    const stationInfo: { [key: number]: number } = {
      321: 1, // Bipolaridad
      322: 2, // Leer+
      323: 3, // Redes Delictuales
      324: 4, // Certeza Jurídica
      325: 5, // Arbocensus
      326: 6, // Biopurificador
      327: 7, // Gel X
      328: 8, // Dialect
      329: 9, // Discapacidad
      330: 10, // Pregnóstica
    };

    const pdfLinks: { [key: number]: string } = {
      1: "https://drive.google.com/file/d/1JsKsuBEWT3lOL2yk3IF6PHz_2QYNb_tu/preview",
      2: "https://drive.google.com/file/d/100k6C7JqttlA-N6r1WVNqCw79RkLIAnE/preview",
      3: "https://drive.google.com/file/d/1wgdmy-reByd81jCzVJdAZAeMzo2fZOWm/preview",
      4: "https://drive.google.com/file/d/19AoXTYFkLO9FSO773Lmpn9JSVpY1_0un/preview",
      5: "https://drive.google.com/file/d/1pERdzONnEVg2bK-PxZlvAYOv_obbhG5F/preview",
      6: "https://drive.google.com/file/d/1XLYT-HsHU1-X1kgNsur6_il3EdROtZQP/preview",
      7: "https://drive.google.com/file/d/1D8PeI3ovgEEWkR8C5NJub2Nu4fNeoNzw/preview",
      8: "https://drive.google.com/file/d/10tNsPhjAywkNzdKjT_5zFyCQEgiy8XX9/preview",
      9: "https://drive.google.com/file/d/10cLBs4aMhd2fw2-T6XGau3Zm6SNdowqD/preview",
      10: "https://drive.google.com/file/d/1i5mXag7pGqJrX2K8G_br5pJDKVOymdeM/preview",
    };

    const stationIdNumber = parseInt(stationId || "0");
    const stationTLR = stationTLRs[stationIdNumber] || 1;

    setTLR(stationTLR);
    setTLRExplanation(explanations[stationTLR]);
    setTLRColor(colors[stationTLR]);

    const stationInfoNumber = stationInfo[stationIdNumber] || 1;
    setPdfLink(pdfLinks[stationInfoNumber]);
    setStationIntelectualProperty(intelectualProperty[stationInfoNumber]);
    setStationPartners(partners[stationInfoNumber]);
  }, []);

  useEffect(() => {
    gsap.killTweensOf(notificationRef.current);
    gsap.set(notificationRef.current, { y: 100, opacity: 0 });
    gsap.set(notificationRef.current, { yPercent: 100 });

    gsap.killTweensOf(moreInfoButtonRef.current);
    gsap.set(moreInfoButtonRef.current, { scale: 0, opacity: 0 });

    gsap.killTweensOf(homeButtonRef.current);
    gsap.set(homeButtonRef.current, { scale: 0, opacity: 0 });

    gsap.set(pdfContainerRef.current, { autoAlpha: 0 });
    gsap.set(pdfRef.current, { scale: 0.5 });

    if (!isLoaded) return;
    //animate notification ref bottom to top
    gsap.to(notificationRef.current, {
      y: 0,
      opacity: 1,
      duration: 1,
      ease: "power2.out",
      delay: 2,
    });

    gsap.to(moreInfoButtonRef.current, {
      scale: 1,
      opacity: 1,
      duration: 0.5,
      ease: "power2.out",
      delay: 3.5,
    });

    gsap.to(homeButtonRef.current, {
      scale: 1,
      opacity: 1,
      duration: 0.5,
      ease: "power2.out",
      delay: 3,
    });
  }, [isLoaded]);
  useEffect(() => {
    const updateDevicePixelRatio = () => {
      setDevicePixelRatio(window.devicePixelRatio);
    };
    const mediaMatcher = window.matchMedia(
      `screen and (resolution: ${devicePixelRatio}dppx)`
    );
    mediaMatcher.addEventListener("change", updateDevicePixelRatio);
    return () => {
      mediaMatcher.removeEventListener("change", updateDevicePixelRatio);
    };
  }, [devicePixelRatio]);

  useEffect(() => {
    gsap.killTweensOf(notificationRef.current);
    gsap.killTweensOf(informativeRef.current);
    if (isNotificationVisible) {
      gsap.to(notificationRef.current, {
        yPercent: 0,
        duration: 1,
        ease: "power2.out",
      });
      gsap.to(informativeRef.current, {
        opacity: 1,
        duration: 1,
        ease: "power2.out",
      });
    } else {
      gsap.to(notificationRef.current, {
        yPercent: 100,
        duration: 1,
        ease: "power2.out",
      });
      gsap.to(informativeRef.current, {
        opacity: 0,
        duration: 1,
        ease: "power2.out",
      });
    }
  }, [isNotificationVisible]);

  return (
    <div
      ref={viewRef}
      className="z-10 fixed w-full h-full left-0 top-0 bg-black w-full h-full absolute top-0 left-0"
    >
      <Unity unityProvider={unityProvider} className="w-full h-full" />
      <div className="absolute top-4 right-4 sm:top-6 sm:right-6 flex space-x-3">
        {isTotemMode === false && (
          <button
            rel="noopener noreferrer"
            className="shadow-md w-10 h-10 p-1 bg-white rounded-full text-2xl flex justify-center items-center"
            ref={moreInfoButtonRef}
            onClick={showPDF}
          >
            <InfoIcon />
          </button>
        )}

        <button
          className="shadow-md w-10 h-10 p-1 bg-white rounded-full text-2xl flex justify-center items-center"
          ref={homeButtonRef}
          onClick={onClosed}
        >
          <HomeIcon />
        </button>
      </div>

      <div
        ref={notificationRef}
        className="absolute left-8 bottom-8 pe-8 cursor-pointer"
        onClick={() => setIsNotificationVisible(!isNotificationVisible)}
      >
        <div className="transform -translate-y-3">
          <GradientBar
            tlr={tlr}
            tlrPercentage={tlrPercentage}
            tlrColor={tlrColor}
          />
        </div>
        <div ref={informativeRef} className="max-w-xs space-y-2">
          <div className="rounded rounded-lg bg-[#E9F0D2] w-full p-4">
            <h5 className="text-xs font-ibm font-bold">
              Estado de madurez tecnológica
            </h5>
            <p className="text-xs font-ibm">{tlrExplanation}</p>
          </div>
          <div className="rounded rounded-lg bg-black text-white w-full p-4">
            <h5 className="text-xs font-ibm font-bold">
              Propiedad Intelectual
            </h5>
            <p className="text-xs font-ibm">{stationIntelectualProperty}</p>
          </div>
          <div className="rounded rounded-lg bg-white text-black w-full p-4">
            <h5 className="text-xs font-ibm font-bold">Socios Clave</h5>
            <p className="text-xs font-ibm">{stationPartners}</p>
          </div>
        </div>
      </div>

      <div
        ref={pdfContainerRef}
        className="absolute z-50 p-5 pb-20 md:p-10 w-full h-full top-0 left-0 overflow-hidden"
      >
        <div
          className="z-[-1] absolute w-full h-full bg-black/80 top-0 left-0"
          onClick={hidePDF}
        ></div>
        <iframe
          ref={pdfRef}
          title="pdf"
          src={pdfLink}
          width="640"
          height="480"
          allow="autoplay"
          className="w-full h-full bg-[#1F1F1F]"
        ></iframe>
      </div>

      {isTotemMode && (
        <div className="absolute bottom-4 right-4 p-3.5 bg-white rounded-2xl">
          {/* Renderizar QR en lugar del PDF */}
          <QRCodeSVG
            value={`${window.location.origin}/minisite/${stationId}`}
            size={100}
          />
        </div>
      )}
      {isLoaded === false && (
        <div className="z-20 fixed w-full h-full text-white left-0 top-0 bg-black flex justify-center items-center">
          <div className="relative flex justify-center items-center">
            <div className="aspect-square relative w-12 md:w-20 lg:w-28">
              <Loader size="100%" />
            </div>
            <div className="relative aspect-square w-12 md:w-20 lg:w-28">
              <svg
                id="Capa_1"
                className="object-contain w-full h-full"
                data-name="Capa 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 781.39 518"
              >
                <g>
                  <path
                    style={{ fill: "#fff", strokeWidth: 0 }}
                    d="M148.94,220.27c26.72,0,47.61,6.57,62.68,19.64,15.06,13.11,22.59,31.06,22.59,53.87,0,17.01-3.87,31.94-11.59,44.78-7.73,12.87-20.11,21.63-37.14,26.35v.77c8.12,1.58,14.74,4.21,19.84,7.88,5.12,3.67,9.18,8.05,12.2,13.14,3,5.12,5.24,10.81,6.67,17.12,1.45,6.27,2.56,12.84,3.35,19.64.52,6.81.91,13.75,1.18,20.82.25,7.08.91,14.02,1.95,20.82,1.04,6.84,2.56,13.31,4.51,19.47,1.97,6.17,4.92,11.59,8.84,16.31h-41.66c-2.61-2.86-4.38-6.81-5.31-11.79-.91-4.99-1.5-10.55-1.77-16.71-.25-6.13-.52-12.77-.77-19.84-.27-7.08-1.06-14.02-2.36-20.82-1.04-6.81-2.36-13.31-3.93-19.44-1.58-6.17-4.08-11.56-7.48-16.14-3.4-4.58-7.85-8.25-13.36-10.98-5.51-2.76-12.84-4.14-22-4.14H54.22v119.87H16.89V220.27h132.05ZM156.79,347.63c7.87-1.31,14.74-3.87,20.64-7.68,5.9-3.77,10.61-8.89,14.15-15.33,3.54-6.4,5.31-14.72,5.31-24.93,0-14.15-3.94-25.67-11.79-34.6-7.87-8.89-20.57-13.38-38.12-13.38H54.22v97.87h77.81c8.66,0,16.91-.64,24.76-1.95Z"
                  />
                  <path
                    style={{ fill: "#fff", strokeWidth: 0 }}
                    d="M398.11,220.27c43.23,0,76.5,11.02,99.81,33.02,23.31,22,34.99,55.56,34.99,100.6,0,23.58-2.63,44.47-7.87,62.7-5.24,18.19-13.36,33.52-24.36,45.99-11.02,12.43-25.03,21.93-42.06,28.47-17.03,6.57-37.2,9.84-60.51,9.84h-96.29V220.27h96.29ZM401.24,469.45c4.19,0,9.43-.34,15.72-.98,6.3-.67,12.97-2.22,20.05-4.72,7.08-2.49,14.08-6.1,21.02-10.81,6.94-4.72,13.17-11.15,18.68-19.27,5.49-8.12,10.01-18.26,13.54-30.46,3.54-12.16,5.31-26.92,5.31-44.2s-1.63-31.77-4.9-45.01c-3.28-13.21-8.66-24.49-16.12-33.79-7.46-9.3-17.1-16.37-28.89-21.23-11.78-4.85-26.33-7.28-43.61-7.28h-62.89v217.75h62.09Z"
                  />
                </g>
                <path
                  style={{ fill: "#fff", strokeWidth: 0 }}
                  d="M571.13,17.11h42.84l48.72,71.16,50.71-71.16h40.09l-69.96,93.53,78.6,109.67h-42.84l-56.6-84.09-56.58,84.09h-40.48l76.24-106.9-70.74-96.29Z"
                />
              </svg>
            </div>
            <div className="w-full bottom-0 absolute text-center transform text-white translate-y-full text-gray-300">
              {loadingPercentage}%
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StationView;
