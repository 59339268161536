import React from "react";

const InfoIcon = () => (
  <svg
    className="w-full h-auto"
    data-name="Info"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 128 128"
  >
    <circle fill="#fff" cx="64" cy="64" r="64" />
    <path
      strokeWidth={0}
      d="M71.02,109.18h-9.82v-57.08h-19.32v-8.5h29.14v65.57ZM66.23,18.82c1.85,0,3.42.66,4.72,1.98,1.3,1.32,1.95,2.91,1.95,4.75s-.64,3.55-1.92,4.85c-1.28,1.3-2.86,1.95-4.75,1.95-1.72,0-3.24-.68-4.56-2.05-1.32-1.36-1.98-2.95-1.98-4.75s.66-3.38,1.98-4.72c1.32-1.34,2.84-2.01,4.56-2.01Z"
    />
  </svg>
);

export default InfoIcon;
