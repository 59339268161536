// ShowIntroductionVideo.tsx
import React, { useState, useRef, useEffect } from "react";
import { InfoIcon } from "../views/StationView/icons"; // Asegúrate de que la ruta sea correcta
import gsap from "gsap";

const ShowIntroductionVideo: React.FC = () => {
  //   const [isModalOpen, setModalOpen] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const modalContentRef = useRef<HTMLDivElement>(null);

  // const iframeRef = useRef<HTMLIFrameElement>(null);
  const [showIframe, setShowIframe] = useState(true);
  //   useEffect(() => {
  //     if (isModalOpen) {
  //       showModal();
  //     } else {
  //       closeModal();
  //     }
  //   });

  useEffect(() => {
    gsap.set(modalRef.current, { autoAlpha: 0, scale: 0.5 });
    gsap.set(modalContentRef.current, { autoAlpha: 0 });
  }, []);

  const showModal = () => {
    console.log("showModal");
    // setModalOpen(true);
    setShowIframe(true);

    // Restablecer estado inicial
    gsap.killTweensOf(modalRef.current);
    gsap.killTweensOf(modalContentRef.current);

    // gsap.set(modalContentRef.current, { autoAlpha: 0 });
    // gsap.set(modalRef.current, { autoAlpha: 0, scale: 0.8 }); // Inicializa estado

    // Animación de entrada
    gsap.to(modalRef.current, {
      autoAlpha: 1,
      duration: 0.6,
      scale: 1,
      ease: "power2.out",
    });

    gsap.to(modalContentRef.current, {
      autoAlpha: 1,
      opacity: 1,
      duration: 0.6,
      ease: "back.out(1.7)", // Efecto de rebote al entrar
    });
  };

  const closeModal = () => {
    console.log("closeModal");
    // Animación de cierre
    gsap.to(modalContentRef.current, {
      autoAlpha: 0,
      duration: 0.6,
      ease: "power2.in",
      onComplete: () => {
        setShowIframe(false);
        requestAnimationFrame(() => {
          setShowIframe(true);
        });
      },
    });
    gsap.to(modalRef.current, {
      scale: 0.5,
      duration: 0.6,
      ease: "power2.in",
      //   onComplete: () => setModalOpen(false),
    });
  };

  return (
    <>
      <div className="absolute top-4 right-4 sm:top-6 sm:right-6 flex space-x-3">
        <button
          rel="noopener noreferrer"
          className="shadow-md w-10 h-10 p-1 bg-white rounded-full text-2xl flex justify-center items-center"
          onClick={showModal}
        >
          <InfoIcon />
        </button>
      </div>

      <div
        ref={modalContentRef}
        className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-3"
        onClick={closeModal}
      >
        <div
          ref={modalRef}
          className="bg-white rounded-lg shadow-lg w-full max-w-screen-md  xl:max-w-screen-lg p-2"
          onClick={(e) => e.stopPropagation()} // Evita el cierre al hacer clic dentro del modal
        >
          {/* <button
            onClick={closeModal}
            className="absolute top-2 right-2 text-2xl"
          >
            &times;
          </button> */}
          {/* <div style={{"padding:56.25% 0 0 0;position:relative;"}}> */}
          <div
            className="rounded-lg overflow-hidden"
            style={{ padding: "56.25% 0 0 0", position: "relative" }}
          >
            {showIframe && (
              <iframe
                src="https://player.vimeo.com/video/1023291247?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                //   style={{"position:absolute;top:0;left:0;width:100%;height:100%;"}}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                title="Intro Impacta U Andes"
              ></iframe>
            )}
          </div>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
      </div>
    </>
  );
};

export default ShowIntroductionVideo;
