import React from "react";

const PopupIcon = () => (
  <svg
    id="Capa_1"
    className="w-full h-auto drop-shadow-xl"
    style={{
      filter: "drop-shadow(rgba(0, 0, 0, 1.5) 0px -1px 10px)",
    }}
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 128 64"
  >
    <defs>
      <style>
        {`.cls-1 {
          fill: #eaf0d2;
          stroke-width: 0px;
        }`}
      </style>
    </defs>
    <path
      className="cls-1"
      d="M90.3,3.36h-52.59c-12.28,0-22.24,9.96-22.24,22.24s9.96,22.24,22.24,22.24h8.83l7.4,12.81,7.4-12.81h28.97c12.28,0,22.24-9.96,22.24-22.24S102.58,3.36,90.3,3.36Z"
    />
  </svg>
);

export default PopupIcon;
