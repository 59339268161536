import { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import Loader from "./Loader";
import { useMapContext } from "../context/MapContext";

const IntroLoader = () => {
  const { mapLoaded, dataLoaded } = useMapContext();

  const canvasRef = useRef<HTMLDivElement>(null);
  //   const { porticosData } = usePorticosData();
  const [minDuration, setMinDuration] = useState(false);

  useEffect(() => {
    // Set a minimum duration of 2 seconds for the loader
    const timer = setTimeout(() => {
      setMinDuration(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!mapLoaded || !dataLoaded || !minDuration) return;

    gsap.killTweensOf(canvasRef.current);
    gsap.set(canvasRef.current, { autoAlpha: 1 });
    gsap.to(canvasRef.current, {
      autoAlpha: 0,
      duration: 0.5,
      delay: 0.15,
      ease: "power2.inOut",
    });
  }, [mapLoaded, dataLoaded, minDuration]);

  return (
    <div
      ref={canvasRef}
      className="fixed z-50 w-full h-full left-0 top-0 bg-black"
    >
      <div className="w-full h-full flex items-center justify-center">
        <div className="aspect-square relative w-12 md:w-20 lg:w-28">
          <Loader size="100%" />
        </div>
        <div className="aspect-square w-12 md:w-20 lg:w-28">
          <svg
            id="Capa_1"
            className="object-contain w-full h-full"
            data-name="Capa 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 781.39 518"
          >
            <g>
              <path
                style={{ fill: "#fff", strokeWidth: 0 }}
                d="M148.94,220.27c26.72,0,47.61,6.57,62.68,19.64,15.06,13.11,22.59,31.06,22.59,53.87,0,17.01-3.87,31.94-11.59,44.78-7.73,12.87-20.11,21.63-37.14,26.35v.77c8.12,1.58,14.74,4.21,19.84,7.88,5.12,3.67,9.18,8.05,12.2,13.14,3,5.12,5.24,10.81,6.67,17.12,1.45,6.27,2.56,12.84,3.35,19.64.52,6.81.91,13.75,1.18,20.82.25,7.08.91,14.02,1.95,20.82,1.04,6.84,2.56,13.31,4.51,19.47,1.97,6.17,4.92,11.59,8.84,16.31h-41.66c-2.61-2.86-4.38-6.81-5.31-11.79-.91-4.99-1.5-10.55-1.77-16.71-.25-6.13-.52-12.77-.77-19.84-.27-7.08-1.06-14.02-2.36-20.82-1.04-6.81-2.36-13.31-3.93-19.44-1.58-6.17-4.08-11.56-7.48-16.14-3.4-4.58-7.85-8.25-13.36-10.98-5.51-2.76-12.84-4.14-22-4.14H54.22v119.87H16.89V220.27h132.05ZM156.79,347.63c7.87-1.31,14.74-3.87,20.64-7.68,5.9-3.77,10.61-8.89,14.15-15.33,3.54-6.4,5.31-14.72,5.31-24.93,0-14.15-3.94-25.67-11.79-34.6-7.87-8.89-20.57-13.38-38.12-13.38H54.22v97.87h77.81c8.66,0,16.91-.64,24.76-1.95Z"
              />
              <path
                style={{ fill: "#fff", strokeWidth: 0 }}
                d="M398.11,220.27c43.23,0,76.5,11.02,99.81,33.02,23.31,22,34.99,55.56,34.99,100.6,0,23.58-2.63,44.47-7.87,62.7-5.24,18.19-13.36,33.52-24.36,45.99-11.02,12.43-25.03,21.93-42.06,28.47-17.03,6.57-37.2,9.84-60.51,9.84h-96.29V220.27h96.29ZM401.24,469.45c4.19,0,9.43-.34,15.72-.98,6.3-.67,12.97-2.22,20.05-4.72,7.08-2.49,14.08-6.1,21.02-10.81,6.94-4.72,13.17-11.15,18.68-19.27,5.49-8.12,10.01-18.26,13.54-30.46,3.54-12.16,5.31-26.92,5.31-44.2s-1.63-31.77-4.9-45.01c-3.28-13.21-8.66-24.49-16.12-33.79-7.46-9.3-17.1-16.37-28.89-21.23-11.78-4.85-26.33-7.28-43.61-7.28h-62.89v217.75h62.09Z"
              />
            </g>
            <path
              style={{ fill: "#fff", strokeWidth: 0 }}
              d="M571.13,17.11h42.84l48.72,71.16,50.71-71.16h40.09l-69.96,93.53,78.6,109.67h-42.84l-56.6-84.09-56.58,84.09h-40.48l76.24-106.9-70.74-96.29Z"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default IntroLoader;
