// src/utils/addMarker.ts
import mapboxgl from "mapbox-gl";
import { StationOverview } from "../types";
// src/utils/calculateDistance.ts
export function haversineDistance(
  [lat1, lon1]: [number, number],
  [lat2, lon2]: [number, number]
) {
  const R = 6371e3; // Earth radius in meters
  const φ1 = (lat1 * Math.PI) / 180;
  const φ2 = (lat2 * Math.PI) / 180;
  const Δφ = ((lat2 - lat1) * Math.PI) / 180;
  const Δλ = ((lon2 - lon1) * Math.PI) / 180;

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c;
  return distance;
}
// src/utils/easing.ts
export function easeInOutQuad(t: number): number {
  return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
}

interface StationMarkerOptions {
  map: mapboxgl.Map;
  station: StationOverview;
  onClick?: (station: StationOverview) => void;
}

const colors = [
  { color: "#ea0029" },
  { color: "#004eff" },
  { color: "#a2ef1b" },
  { color: "#d41aff" },
  { color: "#0dc4ff" },
  { color: "#ffb0ff" },
  { color: "#ff6900" },
  { color: "#ffd300" },
  { color: "#26a69a" },
  { color: "#b71c1c" },
];

export function addStationMarker({
  map,
  station,
  onClick,
}: StationMarkerOptions) {
  const { name, description, transform, station_id } = station;
  const { x: lat, z: lng } = transform.position;

  const markerContainer = document.createElement("div");
  markerContainer.className = "marker-container";

  const marker = document.createElement("div");
  marker.className = "marker";

  const attemptIndex = station_id - 321;
  if (colors[attemptIndex]) {
    marker.style.backgroundColor = colors[attemptIndex].color;
  } else
    marker.style.backgroundColor =
      colors[Math.floor(Math.random() * colors.length)].color;

  const label = document.createElement("div");
  label.className = "marker-label";
  label.textContent = name;

  markerContainer.appendChild(marker);
  markerContainer.appendChild(label);

  if (description !== "") {
    const subtitle = document.createElement("span");
    subtitle.className = "marker-subtitle";
    subtitle.textContent = "/" + description;
    label.appendChild(subtitle);
  }

  markerContainer.addEventListener("click", () => {
    map.flyTo({
      center: [lng, lat],
      zoom: 2.5,
      speed: 0.3,
      curve: 1.5,
      easing: (t) => t * (2 - t),
    });

    map.once("moveend", () => {
      if (onClick) {
        onClick(station);
      }
    });
  });

  const mapMarker = new mapboxgl.Marker(markerContainer)
    .setLngLat([lng, lat])
    .addTo(map);

  const updateMarkerSize = () => {
    const center = map.getCenter();
    const distanceToCenter = haversineDistance(
      [lat, lng],
      [center.lat, center.lng]
    );
    const minDistance = 3000000;
    const maxDistance = 9000000; // Maximum distance in meters to consider for scaling, adjust as needed
    const normalizedDistance = Math.min(
      1,
      Math.max(
        0,
        (distanceToCenter - minDistance) / (maxDistance - minDistance)
      )
    );
    const scaleFactor = easeInOutQuad(1 - normalizedDistance);
    const zoomFactor = map.getZoom() / 2.5;
    label.style.transform = `scale(${scaleFactor * zoomFactor})`;
  };

  map.on("move", updateMarkerSize);
  updateMarkerSize(); // Initial size update

  return mapMarker;
}
