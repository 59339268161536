import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const stationInfo: { [key: number]: number } = {
  321: 1, // Bipolaridad
  322: 2, // Leer+
  323: 3, // Redes Delictuales
  324: 4, // Certeza Jurídica
  325: 5, // Arbocensus
  326: 6, // Biopurificador
  327: 7, // Gel X
  328: 8, // Dialect
  329: 9, // Discapacidad
  330: 10, // Pregnóstica
};

const pdfLinks: { [key: number]: string } = {
  1: "https://drive.google.com/file/d/1JsKsuBEWT3lOL2yk3IF6PHz_2QYNb_tu/preview",
  2: "https://drive.google.com/file/d/100k6C7JqttlA-N6r1WVNqCw79RkLIAnE/preview",
  3: "https://drive.google.com/file/d/1wgdmy-reByd81jCzVJdAZAeMzo2fZOWm/preview",
  4: "https://drive.google.com/file/d/19AoXTYFkLO9FSO773Lmpn9JSVpY1_0un/preview",
  5: "https://drive.google.com/file/d/1pERdzONnEVg2bK-PxZlvAYOv_obbhG5F/preview",
  6: "https://drive.google.com/file/d/1XLYT-HsHU1-X1kgNsur6_il3EdROtZQP/preview",
  7: "https://drive.google.com/file/d/1D8PeI3ovgEEWkR8C5NJub2Nu4fNeoNzw/preview",
  8: "https://drive.google.com/file/d/10tNsPhjAywkNzdKjT_5zFyCQEgiy8XX9/preview",
  9: "https://drive.google.com/file/d/10cLBs4aMhd2fw2-T6XGau3Zm6SNdowqD/preview",
  10: "https://drive.google.com/file/d/1i5mXag7pGqJrX2K8G_br5pJDKVOymdeM/preview",
};

const Minisite: React.FC = () => {
  const { stationId } = useParams<{ stationId: string }>();
  const [pdfLink, setPdfLink] = useState<string>("");

  useEffect(() => {
    const id = parseInt(stationId || "0");
    const pdfIndex = stationInfo[id]; // Usa stationInfo para obtener el índice
    setPdfLink(pdfLinks[pdfIndex] || pdfLinks[1]); // Usa el enlace por defecto si el índice no es válido
  }, [stationId]);

  return (
    <div className="fixed inset-0 w-full h-full flex justify-center items-center bg-gray-100">
      {pdfLink ? (
        <iframe
          src={pdfLink}
          title="PDF Viewer"
          width="100%"
          height="100%"
          style={{ border: "none" }}
        ></iframe>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default Minisite;
